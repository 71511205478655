.Form {
  margin: 0;
  padding: 0;
  background: linear-gradient(indigo, navy);
  height: 100vh;
  font-family: Verdana, Geneva, sans-serif;
  color: white;
  text-align: center;


  h1 {
    font-weight: 100;
    font-size: 3rem;
    letter-spacing: 0.02em;
    margin-bottom: 0;
  }

  .container {
    margin: 0 auto;
    width: 500px;
  }

  input {
    width: 100%;
    font-size: 1.5rem;
    padding: 0.25em;
    margin-top: 0.5em;
    border: 3px solid black;
  }

  button {
    background: orange;
    font-size: 1.3rem;
    margin: 1em;
    padding: 0.2em 0.5em;
  }

  .error-input {
    border: 2px solid red;
    border-radius: 4px;
  }

  .error-text {
    margin: 0.25em 0 0 0;
    padding: 0;
    color: red;
    font-style: bold;
    font-size: 1.2rem;
  }

}
